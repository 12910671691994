import React from "react"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import { connect } from "react-redux"
import Indo1 from "../images/key-indo/AsraAlFauziMDPhD.jpg"
import Indo2 from "../images/key-indo/FerdiansyahMDPhD.jpg"
import Indo3 from "../images/key-indo/H.BintangSoetjahjoMDPhD.jpg"
import Indo4 from "../images/key-indo/HeriSurotoMDPhD.jpg"
import Indo5 from "../images/key-indo/IsabellaKurniaLiemMDPhD.jpg"
import Indo6 from "../images/key-indo/M.HardianBasukiMD.jpg"
import Indo7 from "../images/key-indo/MouliEdwardMD.jpg"
import Indo8 from "../images/key-indo/NazlyHilmyMsPharmPhD.jpg"
import Indo9 from "../images/key-indo/Prof.CitaRositaSigitPrakoeswaMDPhD.jpg"
import Indo10 from "../images/key-indo/Prof.DwikoraNovembriUtomoMDPhD.jpg"
import Indo11 from "../images/key-indo/Prof.FedikAbdulRantamVMDPhD.jpg"
import Indo12 from "../images/key-indo/Prof.IsmailHadisoebrotoDilogoMDPhD.jpg"
import Indo13 from "../images/key-indo/Prof.JeanneAdiwinataPMDPhD.jpg"
import Indo14 from "../images/key-indo/RahyussalimMDPhD.jpg"
import Indo15 from "../images/key-indo/RizkiRahmadianMDPhD.jpg"
import Indo16 from "../images/key-indo/SittiRizaliyanaMD.jpg"
import Indo17 from "../images/key-indo/TriWahyuMartantoMD.jpg"
import Indo18 from "../images/key-indo/YuanitaSafitriDiantiMD.jpg"
import Apastb1 from "../images/key-apastb/AstridLoboGajiwalaPhDINDIA.jpg"
import Apastb2 from "../images/key-apastb/CynthiaDLima.jpg"
import Apastb3 from "../images/key-apastb/NorimahYusofPhDMALAYSIA.jpg"
import Apastb4 from "../images/key-apastb/Prof.AzisNatherPhDSIN.jpg"
import Apastb5 from "../images/key-apastb/Prof.KenUrabeMDPhDJEPANG.jpg"
import Apastb6 from "../images/key-apastb/Prof.SuzinaSheikhAbHamidPhDMALAYSIA.jpg"
import Apastb7 from "../images/key-apastb/Prof.Yong-KooKangMDPhDKOREA.jpg"
import Non1 from "../images/key-non/MarisaRomaHersonMDPhDAUSTRALIA.jpg"
import Non2 from "../images/key-non/MartiManyalichMDPhDSPAIN.jpg"
import Non3 from "../images/key-non/MartinBorgelGERMANY.jpg"
import Non4 from "../images/key-non/Prof.ArnoldI.CaplanPhDUSA.jpg"
import Non5 from "../images/key-non/Prof.GilsonKangPhDSOUTHKOREA.jpg"
import Non6 from "../images/key-non/RamadanJashriPhDBELGIUM.jpg"

const HomeKeynotes = () => {
  const keyNotesNames = [
    { image: Indo1, nama: "Asra Al Fauzi, MD, PhD", country: "Indonesia" },
    { image: Indo2, nama: "Ferdiansyah, MD, PhD", country: "Indonesia" },
    {
      image: Indo3,
      nama: "H. Bintang Soetjahjo, MD, PhD",
      country: "Indonesia",
    },
    { image: Indo4, nama: "Heri Suroto, MD, PhD", country: "Indonesia" },
    {
      image: Indo5,
      nama: "Isabella Kurnia Liem, MD, PhD",
      country: "Indonesia",
    },
    { image: Indo6, nama: "M. Hardian Basuki, MD", country: "Indonesia" },
    { image: Indo7, nama: "Mouli Edward, MD", country: "Indonesia" },
    { image: Indo8, nama: "Nazly Hilmy, Ms Pharm, PhD", country: "Indonesia" },
    {
      image: Indo9,
      nama: "Prof. Cita Rosita Sigit Prakoeswa, MD, PhD ",
      country: "Indonesia",
    },
    {
      image: Indo10,
      nama: "Prof. Dwikora Novembri Utomo, MD, PhD",
      country: "Indonesia",
    },
    {
      image: Indo11,
      nama: "Prof. Fedik Abdul Rantam, VMD, PhD",
      country: "Indonesia",
    },
    {
      image: Indo12,
      nama: "Prof. Ismail Hadisoebroto Dilogo, MD, PhD",
      country: "Indonesia",
    },
    {
      image: Indo13,
      nama: "Prof. Jeanne Adiwinata P, MD, PhD",
      country: "Indonesia",
    },
    { image: Indo14, nama: "Rahyussalim, MD, PhD", country: "Indonesia" },
    { image: Indo15, nama: "Rizki Rahmadian, MD, PhD", country: "Indonesia" },
    { image: Indo16, nama: "Sitti Rizaliyana, MD", country: "Indonesia" },
    { image: Indo17, nama: "Tri Wahyu Martanto, MD", country: "Indonesia" },
    { image: Indo18, nama: "Yuanita Safitri Dianti, MD", country: "Indonesia" },
    { image: Apastb1, nama: "Astrid Lobo Gajiwala, PhD", country: "India" },
    { image: Apastb2, nama: "Cynthia D'Lima", country: "India" },
    { image: Apastb3, nama: "Norimah Yusof, PhD", country: "Malaysia" },
    { image: Apastb4, nama: "Prof. Aziz Nather, PhD", country: "Singapore" },
    { image: Apastb5, nama: "Prof. Ken Urabe, MD, PhD", country: "Japan" },
    {
      image: Apastb6,
      nama: "Prof. Suzina Sheikh Ab Hamid, PhD",
      country: "Malaysia",
    },
    {
      image: Apastb7,
      nama: "Prof. Yong-Koo Kang, MD, PhD",
      country: "South Korea",
    },
    { image: Non1, nama: "Marisa Roma Herson, MD, PhD", country: "Australia" },
    { image: Non2, nama: "Marti Manyalich, MD, PhD", country: "Spain" },
    { image: Non3, nama: "Martin Börgel", country: "Germany" },
    { image: Non4, nama: "Prof. Arnold I. Caplan, PhD", country: "U S A" },
    { image: Non5, nama: "Prof. Gilson Khang, PhD", country: "South Korea" },
    { image: Non6, nama: "Ramadan Jashari, PhD", country: "Belgium" },
  ]
  return (
    <div className="section bg-transparent mt-0 border-0">
      <div className="container clearfix">
        <div className="row align-items-center col-mb-50">
          <div className="col-md-12">
            <div className="heading-block center">
              <h2>
                Meet Our <span>Keynote Speaker</span>
              </h2>
              <span>
                Join us in the engaging discussion with expert in Building
                Bridge in Biomaterial, Tissue Engineering and Cell Therapy.
              </span>
            </div>

            <Carousel
              additionalTransfrom={0}
              arrows
              autoPlay
              autoPlaySpeed={3000}
              centerMode={false}
              className=""
              containerClass="container-with-dots"
              dotListClass=""
              draggable
              focusOnSelect={false}
              infinite
              itemClass=""
              keyBoardControl
              minimumTouchDrag={80}
              renderButtonGroupOutside={false}
              renderDotsOutside={false}
              responsive={{
                desktop: {
                  breakpoint: {
                    max: 3000,
                    min: 1024,
                  },
                  items: 4,
                  partialVisibilityGutter: 40,
                },
                mobile: {
                  breakpoint: {
                    max: 464,
                    min: 0,
                  },
                  items: 1,
                  partialVisibilityGutter: 30,
                },
                tablet: {
                  breakpoint: {
                    max: 1024,
                    min: 464,
                  },
                  items: 2,
                  partialVisibilityGutter: 30,
                },
              }}
              showDots={false}
              sliderClass=""
              slidesToSlide={2}
              swipeable
            >
              {keyNotesNames.map((people, i) => {
                return (
                  <div key={i}>
                    <div
                      className="portfolio-item"
                      style={{ cursor: "pointer" }}
                    >
                      <div className="portfolio-image">
                        <img src={people.image} alt="Speaker" />
                      </div>
                      <div className="portfolio-desc">
                        <h5 className="nat">{people.country}</h5>
                        <h3>{people.nama}</h3>
                      </div>
                    </div>
                  </div>
                )
              })}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect()(HomeKeynotes)
