import React, { useState } from "react"
import { connect } from "react-redux"
import { Modal } from "antd"

const AnnouncementModal = () => {
  const [visible, setVisible] = useState(true)
  return (
    <Modal
      id="content"
      visible={visible}
      footer={null}
      width={800}
      onCancel={() => setVisible(false)}
      title="Thank you for joining APASTB 2021!"
    >
      <section id="content">
        <p>This conference starts from March 19th-21st, 2021.</p>

        <p>
          For all registered participants, the conference is held via ZOOM
          WEBINAR. The access link to the conference has been sent to your
          email.{" "}
        </p>

        <p>
          Should you have any difficulty to join the conference, you may reach
          us through whatsApp{" "}
          <a href="https://wa.me/+6282231188768">+6282231188768</a>
        </p>

        <p>We wish you an insightful event!</p>
      </section>
    </Modal>
  )
}

export default connect()(AnnouncementModal)
