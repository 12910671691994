import React from "react"
import { connect } from "react-redux"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HomeCarouselLarge from "../components/part/homeCarouselLarge"
import HomeCarouselSmall from "../components/part/homeCarouselSmall"
import HomeCountdown from "../components/part/homeCountdown"
import HomeScope from "../components/part/homeScope"
import HomeParticipants from "../components/part/homeParticipants"
import HomeTimeline from "../components/part/homeTimeline"
import HomeKeynotes from "../components/part/homeKeynotes"
import HomeSupport from "../components/part/homeSupport"
import HomePromo from "../components/part/homePromo"
import AnnouncementModal from "../components/part/announcementModal"

const IndexPage = ({ state, dispatch }) => {
  return (
    <Layout>
      <SEO title="Home" />
      <AnnouncementModal></AnnouncementModal>
      <HomeCarouselLarge></HomeCarouselLarge>
      <HomeCarouselSmall></HomeCarouselSmall>
      <HomeCountdown></HomeCountdown>
      <HomeScope></HomeScope>
      <HomeParticipants></HomeParticipants>
      <HomeTimeline></HomeTimeline>
      <HomeKeynotes></HomeKeynotes>
      <div className="divider divider-center mt-0 mb-6">
        <i className="icon-circle" />
      </div>
      <HomeSupport></HomeSupport>
      <HomePromo></HomePromo>
    </Layout>
  )
}

export default connect(state => ({}), null)(IndexPage)
