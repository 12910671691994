import React from "react"
import { connect } from "react-redux"

const HomeScope = () => {
  return (
    <div className="section bg-transparent mt-0 border-0">
      <div className="container clearfix">
        <div className="row align-items-center col-mb-50">
          <div className="col-md-6">
            <div className="heading-block" style={{ paddingTop: "40px" }}>
              <h2>Scope and Activities</h2>
              <span>
                The topics covered in this event is Building bridge in
                Biomaterial, Tissue Engineering and Cells Therapy in the form of
                International Conference and Workshop.
              </span>
            </div>
          </div>
          <div className="col-md-6 text-center">
            <iframe
              title="video bank jaringan dan sel"
              src="https://www.youtube.com/embed/PXJ6g8e8RVw"
              width="640"
              height="360"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect()(HomeScope)
