import React from "react"
import { connect } from "react-redux"
import Countdown from "react-countdown"

const HomeCountdown = () => {
  const title = "Countdown to the Conference"
  const message = `We're looking forward to welcoming you on this virtual event on March 19 - 21, 2021`
  const deadline = new Date("March 19, 2021 00:00:00").getTime()
  const renderer = ({ days, hours, minutes, seconds }) => {
    return (
      <div className="col-md-6 countdis">
        <div className="center margineee">
          <h1 className="timecount mb-0">{days}</h1>
          <p>Days</p>
        </div>
        <div className="center margineee">
          <h1 className="timecount mb-0">{hours}</h1>
          <p>Hours</p>
        </div>
        <div className="center margineee">
          <h1 className="timecount mb-0">{minutes}</h1>
          <p>Minutes</p>
        </div>
        <div className="center margineee">
          <h1 className="timecount mb-0">{seconds}</h1>
          <p>Seconds</p>
        </div>
      </div>
    )
  }
  return (
    <div className="section bg-transparent mt-0 border-0">
      <div className="container clearfix">
        <div className="row align-items-center mb-min5">
          <div className="col-md-6">
            <div className="heading-block headbottom" style={{ paddingTop: "40px" }}>
              <h2>{title}</h2>
              <span>{message}</span>
            </div>
          </div>
          <Countdown date={deadline} renderer={renderer}></Countdown>
        </div>
      </div>
    </div>
  )
}

export default connect()(HomeCountdown)
