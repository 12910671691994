import React from "react"
import { connect } from "react-redux"
import Student from "../images/student.svg"
import Profesionals from "../images/pro.svg"
import Doctor from "../images/doctor.svg"
import Researcher from "../images/res.svg"

const HomeScope = () => {
  return (
    <div className="section bg-transparent mt-0 border-0">
      <div className="container clearfix">
        <div className="row align-items-center col-mb-50">
          <div className="col-md-12">
            <div
              className="heading-block center"
              style={{ paddingTop: "40px" }}
            >
              <h2>Participants</h2>
              <span>
                This virtual event will bring together leaders, scientists,
                clinicians, ethicists, and educators from across the asia
                pacific to connect, collaborate, and discuss.
              </span>
            </div>

            <div className="container clearfix">
              <div className="row col-mb-50">
                <div className="col-sm-6 col-lg-3">
                  <div className="feature-box fbox-center fbox-plain border-bottom-0">
                    <div className="fbox-icon">
                      <img
                        src={Student}
                        alt="Student"
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    <div className="fbox-content">
                      <h3>Student</h3>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3">
                  <div className="feature-box fbox-center fbox-plain border-bottom-0">
                    <div className="fbox-icon">
                      <img
                        src={Doctor}
                        alt="Doctor"
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    <div className="fbox-content">
                      <h3>General Practitioner</h3>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3">
                  <div className="feature-box fbox-center fbox-plain border-bottom-0">
                    <div className="fbox-icon">
                      <img
                        src={Profesionals}
                        alt="Professionals"
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    <div className="fbox-content">
                      <h3>Professionals</h3>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3">
                  <div className="feature-box fbox-center fbox-plain border-bottom-0">
                    <div className="fbox-icon">
                      <img
                        src={Researcher}
                        alt="Researcher"
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    <div className="fbox-content">
                      <h3>Researcher</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect()(HomeScope)
