import React from "react"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import { connect } from "react-redux"
import Sup1 from "../images/sup/1.png"
import Sup2 from "../images/sup/2.png"
import Sup3 from "../images/sup/3.png"
import Sup4 from "../images/sup/4.png"
import Sup5 from "../images/sup/5.png"
import Sup6 from "../images/sup/6.png"
import Sup7 from "../images/sup/7.png"
import Sup8 from "../images/sup/8.png"
import Sup9 from "../images/sup/9.png"
import Sup10 from "../images/sup/10.png"
import Sup11 from "../images/sup/11.png"
import Sup12 from "../images/sup/12.png"
import Sup13 from "../images/sup/13.png"
import Sup14 from "../images/sup/14.png"
import Sup15 from "../images/sup/15.png"
import Sup16 from "../images/sup/16.png"
import Sup17 from "../images/sup/17.png"
import { Link } from "gatsby"

const images = [
  Sup1,
  Sup2,
  Sup3,
  Sup4,
  Sup5,
  Sup6,
  Sup7,
  Sup8,
  Sup9,
  Sup10,
  Sup11,
  Sup12,
  Sup13,
  Sup14,
  Sup15,
  Sup16,
  Sup17,
]

const HomeSupport = () => {
  return (
    <div className="section bg-transparent mt-0 mb-0">
      <Carousel
        additionalTransfrom={0}
        arrows={false}
        autoPlay
        autoPlaySpeed={1700}
        centerMode={false}
        className=""
        containerClass="container-with-dots"
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024,
            },
            items: 6,
            partialVisibilityGutter: 40,
          },
          mobile: {
            breakpoint: {
              max: 464,
              min: 0,
            },
            items: 2,
            partialVisibilityGutter: 30,
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 464,
            },
            items: 4,
            partialVisibilityGutter: 30,
          },
        }}
        showDots={false}
        sliderClass=""
        slidesToSlide={2}
        swipeable
      >
        {images.map((image, i) => {
          return (
            <div key={i} className="oc-item">
              <Link to="#">
                <img src={image} alt="Support"></img>
              </Link>
            </div>
          )
        })}
      </Carousel>
    </div>
  )
}

export default connect()(HomeSupport)
