import React from "react"
import { connect } from "react-redux"

const HomeTimeline = () => {
  return (
    <div className="section bg-transparent mt-0 border-0">
      <div className="container clearfix">
        <div className="row align-items-center col-mb-50">
          <div className="col-md-12">
            <div
              className="heading-block center"
              style={{ paddingTop: "40px" }}
            >
              <h2>Program Timeline</h2>
              <span>This is the conference timeline</span>
            </div>
            <div className="container clearfix">
              <div className="row col-mb-50">
                <div className="col-lg-6">
                  <h4 className="color-apastd">Abstract Submission</h4>
                  <div className="table-responsive">
                    <table className="table timeline table-striped">
                      <thead>
                        <tr>
                          <th className="date-wd">Dates</th>
                          <th>Events</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <span className="badge badge-danger">
                              24 February 2021
                            </span>
                          </td>
                          <td>Deadline Abstract Submission</td>
                        </tr>
                        <tr>
                          <td>
                            <span className="badge badge-danger">
                              3 March 2021
                            </span>
                          </td>
                          <td>Abstract Revision</td>
                        </tr>
                        <tr>
                          <td>
                            <span className="badge badge-danger">
                              7 March 2021
                            </span>
                          </td>
                          <td>Notification of Acceptance</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* <div className="col-lg-4">
                    <h4 className="color-apastd">Full Paper</h4>
                    <div className="table-responsive">
                        <table className="table table-striped">
                        <thead>
                            <tr>
                            <th className="date-wd">Dates</th>
                            <th>Events</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td><span className="badge badge-danger">02 November 2020</span></td>
                            <td>Full Paper Submission</td>
                            </tr>
                            <tr>
                            <td><span className="badge badge-danger">09 November 2020</span></td>
                            <td>Deadline Full Paper</td>
                            </tr>
                            <tr>
                            <td><span className="badge badge-danger">23 November 2020</span></td>
                            <td>Deadline Full Paper Revision</td>
                            </tr>
                            <tr>
                            <td><span className="badge badge-danger">30 November 2020</span></td>
                            <td>Notification of Acceptance</td>
                            </tr>
                            <tr>
                            <td><span className="badge badge-danger">14 December 2020</span></td>
                            <td>Deadline of Payment Full Paper</td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    </div> */}
                <div className="col-lg-6">
                  <h4 className="color-apastd">Important Dates</h4>
                  <div className="table-responsive">
                    <table className="table timeline table-striped">
                      <thead>
                        <tr>
                          <th className="date-wd">Dates</th>
                          <th>Events</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <span className="badge badge-danger">
                              19 &mdash; 21 March 2021
                            </span>
                          </td>
                          <td>Virtual Conference</td>
                        </tr>
                        <tr>
                          <td>
                            <span className="badge badge-danger">
                              21 March 2021
                            </span>
                          </td>
                          <td>Live Demo</td>
                        </tr>
                        <tr>
                          <td>
                            <span className="badge badge-danger">
                              27 &mdash; 28 March 2021
                            </span>
                          </td>
                          <td>Hands On Workshop</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect()(HomeTimeline)
