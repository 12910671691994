import React from "react"
import { connect } from "react-redux"
import { Carousel } from "react-responsive-carousel"
import { REGISTER } from "../../constants/routes"
import { Link } from "gatsby"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import SliderSmall1 from "../images/sliderm_1.jpg"
import SliderSmall2 from "../images/sliderm_2.jpg"
import SliderSmall3 from "../images/sliderm_3.jpg"

const HomeCarousel = () => {
  return (
    <div className="sliderSmall">
      <Carousel autoPlay infiniteLoop={true} showThumbs={false}>
        <div>
          <img src={SliderSmall1} alt="SliderSmall1" />
          <Link
            to={REGISTER}
            className="legend button button-reg button-3d button-circle gradient-grey-orange mr-0 text-center mb-3 mt-5"
          >
            Register Now!
          </Link>
        </div>
        <div>
          <img src={SliderSmall2} alt="SliderSmall2" />
        </div>
        <div>
          <img src={SliderSmall3} alt="SliderSmall3" />
        </div>
      </Carousel>
    </div>
  )
}

export default connect()(HomeCarousel)
