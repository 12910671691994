import React from "react"
import { connect } from "react-redux"
import { Link } from "gatsby"
import { REGISTER } from "../../constants/routes"

const HomePromo = () => {
  const linkStyle = {
    lineHeight: "36px",
    fontSize: "0.85rem",
    padding: "8px 32px",
  }
  return (
    <div className="promo promo-light promo-full border-top-0 p-5">
      <div className="container clearfix">
        <div className="row align-items-center">
          <div className="col-12 col-lg">
            <h3>
              Immerse yourself in the largest virtual exhibit hall dedicated to
              stem cell research and advancement now!
            </h3>
          </div>
          <div className="col-12 col-lg-auto mt-4 mt-lg-0">
            <Link
              to={REGISTER}
              style={linkStyle}
              className="button button-3d button-circle gradient-grey-orange text-right m-0"
            >
              <span>Registration</span> <i className="icon-angle-right" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect()(HomePromo)
